import { differenceInDays } from 'date-fns';
import claimStatusEnum from '../claimStatusEnum';

export const customerActionTypeEnum = {
  NONE: 'none',
  TAKE_CUSTOMER_ACTION: 'take_customer_action',
  VIEW_DECLINED_REASON: 'view_declined_reason',
  DISPUTE_DECLINED_REASON: 'dispute_declined_reason',
};

/**
 * @param {object} claim
 * @param {string} claim.freshdeskTicketUrl
 * @param {string} claim.status
 * @param {string} claim.rejectedAt
 * @returns {string} customerActionTypeEnum
 */

export function getActionTypeForClaim(claim) {
  const { freshdeskTicketUrl, status, rejectedAt, isDeclinedPastTakeActionDeadline } = claim || {};

  if (!freshdeskTicketUrl) {
    return customerActionTypeEnum.NONE;
  }

  if (status === claimStatusEnum.ACTION_REQUIRED) {
    return customerActionTypeEnum.TAKE_CUSTOMER_ACTION;
  }

  if (status === claimStatusEnum.DECLINED) {
    return rejectedAt && isDisputePossible(new Date(rejectedAt), isDeclinedPastTakeActionDeadline)
      ? customerActionTypeEnum.DISPUTE_DECLINED_REASON
      : customerActionTypeEnum.VIEW_DECLINED_REASON;
  }

  return customerActionTypeEnum.NONE;
}

/**
 * @param {Date} rejectedAt
 * @returns {boolean}
 */
function isDisputePossible(rejectedAt, isDeclinedPastTakeActionDeadline) {
  if (isDeclinedPastTakeActionDeadline) {
    return false;
  }

  const diff = differenceInDays(new Date(), rejectedAt);
  return diff <= 14;
}
