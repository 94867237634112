import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import CurrencyInput from '../common/CurrencyInput';
import Uploader from '../../../common/Uploader';
import { invoiceInfoValidator } from '../claimValidators';
import useStepValidation from './useStepValidation';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import InputHelpLink from '../InputHelpLink';
import { getCurrencyOfCountry } from '../claimsHelpers';
import { Input } from '../../../common2/Input/Input';
import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';
import Column from '../../../common/layout/Column';

function InvoiceInfoStep(props) {
  const { as: Container, claim, onChange, onNext, ...restProps } = props;

  const { shopClaimId } = claim;
  const [errors, onClickNext] = useStepValidation(claim, onNext, invoiceInfoValidator);
  const [isDisabled, setIsDisabled] = useState(false);

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const uploaderHelpLinkElement = (
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Invoice-Requirements%C2%A0">
      Invoice requirements
    </InputHelpLink>
  );

  useEffect(() => {
    if (!claim.netValueCurrency) {
      onChange({ netValueCurrency: getCurrencyOfCountry(claim.carrierCountryCode) });
    }
  }, []);

  return (
    <Container {...restProps} onNext={onClickNext} isDisabled={isDisabled}>
      <Column spacing="medium">
        <SidePanelContentSection heading="Invoice information">
          <ColumnsLayout>
            <CurrencyInput
              amount={claim.netValue}
              currency={claim.netValueCurrency}
              amountName="netValue"
              currencyName="netValueCurrency"
              label="NET_VALUE_OF_INVOICE"
              onChange={onChange}
              error={errors.netValue}
              isRequired
              noErrorTranslation
            />
          </ColumnsLayout>
          <Uploader
            name="invoiceDocuments"
            files={claim.invoiceDocuments}
            label="Invoice document"
            topRightElement={uploaderHelpLinkElement}
            isRequired
            errorWithTranslation={errors.invoiceDocuments}
            allowedFileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
            helpText="CLAIMS_INVOICE_UPLOAD_HELP_TEXT"
            onChange={onInputChange}
            onBusy={setIsDisabled}
            maxNumberOfFiles={10}
          />
        </SidePanelContentSection>
        <SidePanelContentSection heading={'Additional information'}>
          <ColumnsLayout>
            <Input
              name="shopClaimId"
              label="Internal claim ID"
              placeholder="Add internal claim ID"
              value={shopClaimId}
              error={errors.shopClaimId}
              onChange={onInputChange}
              noErrorTranslation
            />
          </ColumnsLayout>
        </SidePanelContentSection>
      </Column>
    </Container>
  );
}

InvoiceInfoStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  claim: PropTypes.object.isRequired,
  isSubmitButton: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.memo(InvoiceInfoStep);
