import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Input from '../../../common2/Input';
import { useYupValidation } from '../../../../hooks/useYupValidation';
import useOnFormChange from '../../../../hooks/useOnFormChange';
import Column from '../../../common/layout/Column';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import Uploader from '../../../common/Uploader';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import Select from '../../../common2/Select';
import CurrencyInput from '../common/CurrencyInput';
import { getCurrencyOfCountry } from '../claimsHelpers';
import CashOnDeliveryStepContainer from './CashOnDeliveryStepContainer';
import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';

const cashOnDeliveryPaymentTypeOptions = [
  { value: 'full', label: 'CASH_ON_DELIVERY_PAYMENT_TYPE_FULL' },
  { value: 'partial', label: 'CASH_ON_DELIVERY_PAYMENT_TYPE_PARTIAL' },
];

const schema = yup.object().shape({
  netValue: yup.number().required().min(0).label('Net value'),
  netValueCurrency: yup.string().required().label('Currency'),
  cashOnDeliveryPaymentType: yup.string().required().label('Payment type'),
  shopClaimId: yup.string().nullable().max(64).label('Internal claim ID'),
});

function CashOnDeliveryPaymentInfoStep(props) {
  const { claim, onChange, onNext, ...restProps } = props;
  const { shopClaimId } = claim;
  const { validate, errors } = useYupValidation(schema);
  const [isDisabled, setIsDisabled] = useState(false);

  const onClickNext = () => {
    if (validate(claim)) {
      onNext();
    }
  };

  const onInputChange = useOnFormChange(onChange);

  useEffect(() => {
    if (!claim.netValueCurrency) {
      onChange({ netValueCurrency: getCurrencyOfCountry(claim.recipientCountryCode || claim.carrierCountryCode) });
    }
  }, []);

  return (
    <CashOnDeliveryStepContainer {...restProps} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Payment information</SidePanelContentHeader>
      <Column spacing="medium">
        <ColumnsLayout>
          <Select
            label="Payment type"
            placeholder="Select payment type"
            name="cashOnDeliveryPaymentType"
            value={claim.cashOnDeliveryPaymentType}
            options={cashOnDeliveryPaymentTypeOptions}
            onChange={onInputChange}
            error={errors.cashOnDeliveryPaymentType}
            isRequired
          />
          <CurrencyInput
            amount={claim.netValue}
            currency={claim.netValueCurrency}
            amountName="netValue"
            currencyName="netValueCurrency"
            label="CASH_ON_DELIVERY_PAYMENT_AMOUNT"
            onChange={onChange}
            error={errors.netValue}
            isRequired
            noErrorTranslation
          />
        </ColumnsLayout>
        <Uploader
          name="invoiceDocuments"
          files={claim.invoiceDocuments}
          label="Invoice document"
          errorWithTranslation={errors.invoiceDocuments}
          allowedFileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
          helpText="CLAIMS_INVOICE_UPLOAD_HELP_TEXT"
          onChange={onInputChange}
          onBusy={setIsDisabled}
          maxNumberOfFiles={10}
        />
        <SidePanelContentSection heading={'Additional information'}>
          <ColumnsLayout>
            <Input
              name="shopClaimId"
              label="Internal claim ID"
              placeholder="Add internal claim ID"
              value={shopClaimId}
              error={errors.shopClaimId}
              onChange={onInputChange}
              noErrorTranslation
            />
          </ColumnsLayout>
        </SidePanelContentSection>
      </Column>
    </CashOnDeliveryStepContainer>
  );
}

CashOnDeliveryPaymentInfoStep.propTypes = {
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(CashOnDeliveryPaymentInfoStep);
